<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.date') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.id') }}</span></div>
           <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="'아이디'" />
         </div>
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>조건</span></div>
           <div>
              <div class="toggle-container">
                <div class="toggle-item">
                  <span class="toggle-title">블랙리스트 포함</span>
                  <input type="checkbox" class="set-switch" v-model="reqData.memBlackYn" />
                </div>
                <div class="toggle-item ml-10">
                  <span class="toggle-title">삭제회원포함</span>
                  <input type="checkbox" class="set-switch" v-model="reqData.memDelYn" />
                </div>
              </div>
           </div>
         </div>
         <div class="searchbar-content">
           <div class="title"><span>DB선택</span></div>
           <div>
            <select>
             <option value="current">현재</option>
             <option value="2021">2021</option>
            </select>
            <button class="btn-search ml-5" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <tbody>
            <tr>
              <th :colspan="tableData.total_rollBonusAmt_list_maxLeng+3">내역</th>
              <th class="font-weight-bold">{{ $t('searchArea.total') }}</th>
            </tr>
            <tr>
              <th class="custom-width">입금 / 출금</th>
              <td class="font-weight-bold">은행 입금</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_cashInAmt_fcRed }"> {{ tableData.total_cashInAmt_comma }} </span>
              </td>
              <td class="font-weight-bold">은행 출금</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_cashOutAmt_fcRed }"> {{ tableData.total_cashOutAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng - 2"></td>
              <td>
                <span :class="{ 'fc-red': tableData.cashSum_fcRed }">{{ tableData.cashSum_comma }}</span>
              </td>
            </tr>
            <tr v-for="(item, idx) in tableData.total_wlAmt_list_new" :key="item">
              <th v-if="idx === 0" :rowspan="tableData.total_wlAmt_list_new.length" class="custom-width">당첨금 / 패배</th>
              <td>{{ item.groupTxt }}</td>
              <td>0</td>
              <template v-if="item.list.length > 1">
                <template v-for="child in item.list" :key="child.groupCode">
                  <td>
                    <div class="wrap">
                      <div class="game_title">{{ child.codename }}</div>
                      <div class="game_content" :class="child.wlAmt>=0?'fc-blue':'fc-red'">{{ numberWithCommas(child.wlAmt) }}</div>
                    </div>
                  </td>
                </template>
              </template>

              <td v-if="item.list.length===1" :colspan="tableData.total_wlAmt_list_maxLeng"></td>
              <td v-if="item.list.length>1 &&  tableData.total_wlAmt_list_maxLeng - item.list.length!==0" :colspan="tableData.total_wlAmt_list_maxLeng - item.list.length"></td>

              <td v-if="idx === 0" :rowspan="tableData.total_wlAmt_list_new.length"><span>{{tableData.wlTotal_comma}}</span></td>
            </tr>
            <tr>
              <th :colspan="tableData.total_rollBonusAmt_list_maxLeng+3" class="custom-width" style="text-align: right !important">{{ $t('table.head.profit') }}</th>
              <td><span>{{tableData.wlTotal_comma}}</span></td>
            </tr>
            <tr v-for="(item, idx) in tableData.total_rollBonusAmt_list_new" :key="item">
              <th v-if="idx === 0" :rowspan="tableData.total_rollBonusAmt_list_new.length" class="custom-width">롤링보너스</th>
              <td>{{ item.groupTxt }}</td>
              <td>0</td>
              <template v-if="item.list.length > 1">
                <template v-for="child in item.list" :key="child.groupCode">
                  <td>
                    <div class="wrap">
                      <div class="game_title">{{ child.codename }}</div>
                      <div class="game_content" :class="child.pointAmt>=0?'fc-blue':'fc-red'">{{ numberWithCommas(child.pointAmt) }}</div>
                    </div>
                  </td>
                </template>
              </template>

              <td v-if="item.list.length===1" :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td v-if="item.list.length>1 &&  tableData.total_rollBonusAmt_list_maxLeng - item.list.length!==0" :colspan="tableData.total_rollBonusAmt_list_maxLeng - item.list.length"></td>

              <td v-if="idx === 0" :rowspan="tableData.total_rollBonusAmt_list_new.length"><span>{{tableData.rollBonusTotal_comma}}</span></td>
            </tr>
            <tr>
              <th rowspan="3" class="custom-width">수동 거래</th>
              <td class="font-weight-bold">조정</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_discrepancyAmt_fcRed }"> {{ tableData.total_discrepancyAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_discrepancyAmt_fcRed }"> {{ tableData.total_discrepancyAmt_comma }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">수동 화폐</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_cashManualAmt_fcRed }"> {{ tableData.total_cashManualAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_cashManualAmt_fcRed }"> {{ tableData.total_cashManualAmt_comma }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">수동 포인트</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_pointManualAmt_fcRed }"> {{ tableData.total_pointManualAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_pointManualAmt_fcRed }"> {{ tableData.total_pointManualAmt_comma }} </span>
              </td>
            </tr>
            <tr>
              <th rowspan="2" class="custom-width">쿠폰</th>
              <td class="font-weight-bold">발행 금액</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_couponAmt_fcRed }"> {{ tableData.total_couponAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_couponAmt_fcRed }"> {{ tableData.total_couponAmt_comma }} </span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">사용된 금액</td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_couponUseAmt_fcRed }"> {{ tableData.total_couponUseAmt_comma }} </span>
              </td>
              <td :colspan="tableData.total_rollBonusAmt_list_maxLeng"></td>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_couponUseAmt_fcRed }"> {{ tableData.total_couponUseAmt_comma }} </span>
              </td>
            </tr>
            <tr>
              <th :colspan="tableData.total_rollBonusAmt_list_maxLeng+3" class="custom-width" style="text-align: right !important">비용</th>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.total_cost_fcRed }"> {{ tableData.total_cost_comma }} </span>
              </td>
            </tr>
            <tr>
              <th :colspan="tableData.total_rollBonusAmt_list_maxLeng+3" class="custom-width" style="text-align: right !important">총 수익</th>
              <td>
                <span class="text-primary" :class="{ 'fc-red': tableData.final_summary_fcRed }"> {{ tableData.final_summary_comma }} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { reportTerm, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'

export default {
  name: 'BetList',
  components: {
    SearchFilter,
    DateSelector,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      reqData: {
        startDate: '',
        endDate: '',
        memId: '',
        memBlackYn: 'N',
        memDelYn: 'N'
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      tableData: {},
      gameList: []
    }
  },
  methods: {
    pageSeach () {
      this.setTableData()
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setGameList () {
      const gameList = await getCode('kplay')
      const groupCode = []
      if (gameList.length !== 0) {
        // 그룹코드 분리
        for (const item of gameList) {
          if (groupCode.indexOf(item.groupCode) === -1) {
            if (item.useYn === 'Y') {
              groupCode.push(item.groupCode)
            }
          }
        }
      }
      this.gameList = groupCode
      // console.log(this.gameList)
    },
    async setTableData () {
      const req = lodash.cloneDeep(this.reqData)
      for (const key in req) {
        if (typeof req[key] === 'boolean') {
          if (req[key]) {
            req[key] = ''
          } else {
            req[key] = 'N'
          }
        }
      }

      const res = await reportTerm(req)
      const data = res.data
      this.tableData = data
      this.tableData.cashSum = Number(this.tableData.total_cashInAmt) + Number(this.tableData.total_cashOutAmt)
      this.tableData.rollBonusTotal = this.tableData.total_rollBonusAmt_list.reduce((data, el) => {
        return data + Number(el.pointAmt)
      }, 0)
      this.tableData.wlTotal = this.tableData.total_wlAmt_list.reduce((data, el) => {
        return data + Number(el.wlAmt)
      }, 0)

      this.tableData.total_cost = Number(this.tableData.rollBonusTotal) + Number(this.tableData.total_discrepancyAmt) + Number(this.tableData.total_cashManualAmt) + Number(this.tableData.total_couponAmt)

      this.tableData.final_summary = Number(this.tableData.cashSum) + Number(this.tableData.wlTotal) + Number(this.tableData.total_cost)
      for (const item in this.tableData) {
        // 게임목록 데이터
        if (Array.isArray(this.tableData[item])) {
          this.tableData[`${item}_new`] = []
          console.log(Array.isArray(this.tableData[item]))
          for (const code of this.gameList) {
            const filter = this.tableData[item].filter(el => el.groupCode == code)
            const obj = {
              groupCode: code,
              groupTxt: this.$t(`gameList.${code}`),
              list: filter
            }
            this.tableData[`${item}_new`].push(obj)
          }
          // 게임목록 재정렬
          this.tableData[`${item}_new`].sort((a, b) => {
            return a.list.length - b.list.length
          })
          this.tableData[`${item}_maxLeng`] = this.tableData[`${item}_new`].reduce((data, el, i, arr) => {
            if (data < el.list.length) {
              return el.list.length
            } else {
              return data
            }
          }, 0)
          console.log(this.tableData[`${item}_maxLeng`])
        }
        if (!isNaN(Number(this.tableData[item]))) {
          if (Number(this.tableData[item]) < 0) {
            this.tableData[`${item}_fcRed`] = true
          }
          this.tableData[`${item}_comma`] = numberWithCommas(Number(this.tableData[item]))
        }
      }
      console.log(this.tableData)
    }
  },
  async created () {
    this.setStartDate()
    this.setEndDate()
    await this.setGameList()
    await this.setTableData()
  }
}
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable td{
  min-width: 50px;
}
.game_title{
  padding-bottom:5px;
  border-bottom:1px solid #dbdbdb;
}
.game_content{
  padding-top:5px;
}
.table-wrapper{
  overflow-x:auto;
}
</style>
